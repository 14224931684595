.print-only {
  display: none;
}

@media print {
  * {
    box-shadow: none !important;
  }

  body {
    font: 13pt Georgia, 'Times New Roman', Times, serif !important;
    line-height: 1.3 !important;
    background: #fff !important;
    color: #000 !important;
  }

  body .print-hidden,
  #klaro,
  .print-button,
  #detail-tabs {
    display: none !important;
  }

  .print-only {
    display: block;
  }

  .typo {
    font-family: Georgia, 'Times New Roman', Times, serif !important;
  }

  *[class^='MuiTypography-'],
  strong.typo,
  span.typo,
  p.typo,
  div.typo {
    font: 13pt Georgia, 'Times New Roman', Times, serif !important;
  }

  .ctn {
    max-width: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .print-no-margin-top {
    margin-top: 0 !important;
  }

  .print-no-padding-top-bottom {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .print-no-border {
    border: 0 !important;
  }

  .MuiPaper-root .MuiBox-root {
    padding: 0 !important;
  }

  #root {
    width: 100%;
    margin: 0;
    float: none;
  }

  @page {
    margin: 2cm;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .print-as-h2,
  .print-as-h3 {
    font-weight: bold !important;
  }

  h1 {
    font-size: 24pt !important;
  }

  h2,
  .print-as-h2 {
    margin-top: 80px !important;
    font-size: 20pt !important;
    color: #000 !important;
  }

  main {
    // h2,
    // .print-as-h2 {
    //   &:first-child {
    //     margin-top: 0 !important;
    //   }
    // }
  }

  h3,
  h4,
  .print-as-h3 {
    font-size: 14pt !important;
    margin-top: 25px !important;
  }

  a {
    page-break-inside: avoid !important;
  }

  blockquote {
    page-break-inside: avoid !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid !important;
    page-break-inside: avoid !important;
  }

  img {
    page-break-before: auto !important;
    page-break-inside: avoid !important;
    page-break-after: auto !important;
    display: block !important;
  }

  p {
    page-break-before: auto !important;
    page-break-inside: avoid !important;
    page-break-after: auto !important;
  }

  table,
  pre {
    page-break-inside: avoid !important;
  }

  ul,
  ol,
  dl {
    page-break-before: avoid !important;
  }

  a:link,
  a:visited,
  a {
    background: transparent !important;
    color: #298167 !important;
    font-weight: bold !important;
    text-decoration: underline !important;
    text-align: left !important;
  }

  a {
    page-break-before: auto !important;
    page-break-inside: avoid !important;
    page-break-after: auto !important;
  }

  a[href^='http']:after {
    content: ' <' attr(href) '> ' !important;
  }

  a:after > img {
    content: '' !important;
  }

  article a[href^='#']:after {
    content: '' !important;
  }

  a:not(:local-link):after {
    content: ' <' attr(href) '> ' !important;
  }

  iframe,
  ins {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
    line-height: 0pt !important;
    white-space: nowrap !important;
  }

  p,
  address,
  li,
  dt,
  dd,
  blockquote {
    font-size: 100% !important;
  }

  code,
  pre {
    font-family: 'Courier New', Courier, mono !important;
  }

  ul,
  ol {
    list-style: square !important;
    margin-left: 18pt !important;
    margin-bottom: 20pt !important;
  }

  li {
    line-height: 1.6em !important;
  }

  .grd {
    display: block !important;
    width: 100% !important;
    flex-basis: auto;
    max-width: none;
    padding: 0 !important;
    margin: 0 !important;
  }

  .MuiCardContent-root {
    padding: 0 !important;
  }

  body .news-feed,
  body .list-item,
  body .grid-item {
    text-decoration: none !important;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #000;
  }

  .news-feed__box {
    padding: 0 !important;
  }

  .news-feed__headline,
  .news-feed__content {
    height: auto !important;
  }

  .news-feed__divider {
    display: none !important;
  }

  .news-feed__date {
    font-size: 14px;
  }

  .news-feed__content {
    text-decoration: none !important;
    color: #000 !important;

    * {
      line-clamp: unset !important;
      -webkit-line-clamp: unset !important;
    }
  }

  .grid-item__headline {
    height: auto !important;
    padding: 0;
  }

  .grid-item__distance {
    padding: 0;
  }

  .grid-item__content {
    .bx {
      padding: 0;
    }
  }

  .detail-gallery {
    display: none !important;
  }

  .print-tab-item {
    border-bottom: 1px solid #000;
    margin-bottom: 30px;
    padding-bottom: 30px;

    .print-as-h3 {
      margin-bottom: 15px;
    }

    &.downloads {
      margin-bottom: 30px !important;
      padding-bottom: 30px !important;

      a {
        display: block;
        font-weight: bold !important;

        * {
          font-weight: bold !important;
          display: inline !important;
        }

        &:after {
          display: block !important;
          font-weight: normal !important;
          font-size: 14px;
          content: ' <https://pflegeplatzboerse-erlangen.de' attr(href) '> ';
          text-decoration: none !important;
          color: #000;
        }
      }
    }
  }

  body .detail__headline,
  body .detail__headline:first-child {
    margin-top: -30px !important;
  }

  .care-info {
    padding: 0 !important;
    border-radius: 0 !important;
    padding-bottom: 30px !important;
    margin-bottom: 30px !important;
    border-bottom: 1px solid #000;
  }

  .care-type-info {
    border-left: 0 !important;
    padding: 0 !important;

    .bx {
      padding: 0 !important;
    }
  }

  .unsorted-list-as-grid {
    display: block !important;
  }
}
